/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  Tab,
  Tabs,
  Checkbox,
  InputLabel,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Formik, Form } from 'formik';
import useStyles from '../Main.style';
import useAuth from '../../../hooks/useAuth';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as yup from 'yup';

// Start Tab Code
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// end Tab Code

const InstallTripswitchHardware = () => {
  const classes = useStyles();
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const schema = yup.object().shape({
    from: yup.date().required(),
    to: yup.date().required().min(yup.ref('from'), 'End date cannot be earlier than start date'),
  });

  const generateReport = () => {
    window.location =
      window.location.origin + '/api/admin/export-excel?from=' + fromDate + '&to=' + toDate;
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Formik
      initialValues={{ from: new Date(), to: new Date() }}
      onSubmit={generateReport}
      validationSchema={schema}
    >
      {({
        errors,
        isSubmitting,
        status,
        isValid,
        dirty,
        touched,
        setFieldError,
        values,
        setFieldValue,
        setSubmitting,
        resetForm,
      }) => {
        return (
          <Form autoComplete="off">
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.container}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h5"
                    component="div"
                    gutterBottom
                    className={classes.StepHeading}
                  >
                    STEP 2 of 5 - INSTALL TRIPSWITCH HARDWARE
                  </Typography>
                  <Typography variant="h2" component="div" className={classes.StepSubHeading}>
                    Hardware Installation Video
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className={classes.VideoBox}>
                    <iframe
                      width="100%"
                      height="300"
                      src="https://www.youtube.com/embed/5Peo-ivmupE"
                      title="Lorem ipsum video - Dummy video for your website"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.GrayColor} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography variant="h4" component="div" className={classes.heading}>
                          Instructions
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className={classes.instructionsBoxContainer}>
                          <ol>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                Take the device out of the box.
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                Use the tape included to tape your current breaker box to the wall.
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                Open your breaker box door and leave it open.
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                Take out the screws (usually 4-6) that connect the breaker box door
                                to the breaker box.
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                Hang TripSwitch and use the screws to attach it to the breaker box
                                making sure to hang the breaker box on the door of the breaker box
                                as well. Once installed, make sure the device can be swung outwards
                                so that you can access all of the breakers without having to use
                                your TripSwitch.
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                CAUTION: MAKE SURE THE MASTER BREAKER SWITCH IS TURNED OFF FOR THIS
                                STEP. Next, connect the energy monitoring device to the main breaker
                                line. It can usually be found at the top of the breaker box (show a
                                picture of a main line and how to install the energy reader)
                              </Typography>
                            </li>
                            <li>
                              <Typography
                                variant="body1"
                                component="div"
                                className={classes.instructionsBoxText}
                              >
                                {' '}
                                Plug the device in. Either use the included breaker to power the
                                device directly from the breaker box (CAUTION: POTENTIAL ELECTRIC
                                SHOCK- USER ASSUMES ALL RISK WITH THIS METHOD. WE HIGHLY SUGGEST A
                                LICENSED AND CERTIFIED ELECTRICIAN INSTALL THE BREAKER IF YOU CHOOSE
                                THIS METHOD. PLEASE ENSURE THAT MASTER BREAKER IS SHUT OFF AND NO
                                ELECTRICITY IS FLOWING TO THE BREAKER BOX BEFORE INSTALLING). OR Use
                                the attached power cord to plug into a power receptacle.
                              </Typography>
                            </li>
                          </ol>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.GrayColor} />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                      >
                        <Typography variant="h4" component="div" className={classes.heading}>
                          Required Tools
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className={classes.iconBoxContainer}>
                          <ul>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={require('../../../assets/images/sun.png').default}
                                    alt="Sun"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Access to sunlight and/or flashlight
                                </Typography>
                              </a>
                            </li>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={require('../../../assets/images/wifi.png').default}
                                    alt="Wifi"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Wireless network connection
                                </Typography>
                              </a>
                            </li>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={require('../../../assets/images/screw-driver.png').default}
                                    alt="Screw Driver"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Phillips head screw driver
                                </Typography>
                              </a>
                            </li>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={require('../../../assets/images/tape.png').default}
                                    alt="Tape"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Tape (included)
                                </Typography>
                              </a>
                            </li>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={require('../../../assets/images/power-cord.png').default}
                                    alt="Power Cord"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Power Cord (included)
                                </Typography>
                              </a>
                            </li>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={
                                      require('../../../assets/images/circuit-breaker.png').default
                                    }
                                    alt="Circuit Breaker"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Additional Circuit Breaker (included)
                                </Typography>
                              </a>
                            </li>
                            <li>
                              <a>
                                <Box className={classes.iconBox} component="span">
                                  <img
                                    className={classes.iconImg}
                                    src={
                                      require('../../../assets/images/circuit-breaker-map.png')
                                        .default
                                    }
                                    alt="Circuit Breaker Map"
                                  />
                                </Box>
                                <Typography
                                  variant="h5"
                                  component="span"
                                  className={classes.iconBoxheading}
                                >
                                  Picture of your circuit breaker map
                                </Typography>
                              </a>
                            </li>
                          </ul>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InstallTripswitchHardware;
